$(document).foundation();

jQuery(document).ready(function ($) {

  // primary nav
  $('.nav-primary').accessibleNav({
    spans: 'hide',
    level2position: 'vertical-bottom',
    level3position: 'horizontal-right'
  });

  //Adjust height of open nav item.
  // $('.nav-primary .ul1 .li1 span.toggler').click(function (e) {
  //   if ($(".nav-primary .ul1 .li1.expanded").is(":visible")) {
  //     console.log("li is open");
  //     $('.nav-primary .ul1 .li1.expanded').height(function (index, height) {
  //       $('.nav-primary .li1.expanded .ul2').css("height", height);
  //       console.log("Increased" + " " + height + "px height");
  //       //If Open li is shorter than it's parent, then shorten the parent ul
  //       if ($(".nav-primary .ul1 .li1.expanded").height() < $(this).parent().height()) {
  //         $(".nav-primary .ul1 .li1.expanded").parent().css('height', height + 90).toggleClass('overflow-hidden');
  //       }
  //     });
  //   } else {
  //     $('.nav-primary .li1 .ul2').removeAttr("style");
  //     $(".nav-primary .ul1 .li1").parent().removeAttr("style").removeClass('overflow-hidden');
  //     console.log("Set heights back to default");
  //   }
  // });

  // $('.nav-primary .ul1 .li1 span.toggler').on('keypress', function (e) {
  //   if (e.which === 13) {
  //     if ($(".nav-primary .ul1 .li1.expanded").is(":visible")) {
  //       console.log("li is open");
  //       $('.nav-primary .ul1 .li1.expanded').height(function (index, height) {
  //         $('.nav-primary .li1.expanded .ul2').css("height", height);
  //         console.log("Increased" + " " + height + "px height");
  //         //If Open li is shorter than it's parent, then shorten the parent ul
  //         if ($(".nav-primary .ul1 .li1.expanded").height() < $(this).parent().height()) {
  //           $(".nav-primary .ul1 .li1.expanded").parent().css('height', height + 90).toggleClass('overflow-hidden');
  //         }
  //       });
  //     } else {
  //       $('.nav-primary .li1 .ul2').removeAttr("style");
  //       $(".nav-primary .ul1 .li1").parent().removeAttr("style").removeClass('overflow-hidden');
  //       console.log("Set heights back to default");
  //     }
  //   }
  // });


  // Smooth scroll to page anchor
  $(function () {
    $('a[href*="#"]:not([href="#"])').click(function () {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 600);
          return false;
        }
      }
    });
  });

  // clear and restore form input values
  var el = $('input[type=text], input[type=email], textarea, input.header-search');
  el.focus(function (e) {
    if (e.target.value === e.target.defaultValue)
      e.target.value = '';
  });
  el.blur(function (e) {
    if (e.target.value === '')
      e.target.value = e.target.defaultValue;
  });

  // Content tabs
  $('.tabs .tab').click(function (e) {
    var tab = "#" + $(this).data('tab');
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    $('.tab-content').not(tab).removeClass('active');
    $(tab).addClass('active');
  });

  // Content accordions
  $('.accordion-heading').click(function (e) {
    var accordion = $(this).parent();
    if ($(accordion).hasClass('active')) {
      $(accordion).removeClass('active');
    } else {
      $('.accordion').not(accordion).removeClass('active');
      $(accordion).addClass('active');
    }
  });

  // Touch Nav Stuff
  $('.touch-menu-show').click(function () {
    $('.touch-menu').addClass('active');
  });

  $('.touch-menu-hide').click(function () {
    $('.touch-menu').removeClass('active');
  });

  $('.nav-primary .li1 .indicator').click(function (e) {
    $(this).parent().toggleClass('expanded');
  });

  $('.search-toggle').click(function (e) {
    $(this).next('.search-form').toggleClass('active');
  });

  $('.close-btn').click(function (e) {
    $('.search-form').removeClass('active');
  });

  // get a quote form
  //$('.content-form').each(function () {
  //  var formSections = $('.form-builder-section').length;
  //  if (formSections >= 2) {
  //    $(this).addClass('multi-part-form');

  //    var firstSection = $('.form-builder-section:first').attr('id');
  //    if (!window.location.hash) {
  //      window.location.hash = '#' + firstSection;
  //    }

  //    formActionButtonDiv().done(formActionButtons());
  //    $('.form-builder-section-next').on('click', function () {
  //      var sectionNext = $(this).attr('href');
  //      //I like whitespace
  //      var loadNextPage = true;
  //      //Get all required divs in this section, and get all spans inside that and loop over em
  //      $(window.location.hash + ' .required  .error-msg').each(function () {
  //        ValidatorValidate($("#" + this.id)[0]); //Force validation on this validator
  //        if ($("#" + this.id).is(":visible")) { //is it visible? If so, we've got prollems.
  //          loadNextPage = false;
  //        }
  //      });
  //      //If any of those validators failed, then we aren't going to let them move on with this button.
  //      if (loadNextPage === true) {
  //        window.location.hash = sectionNext;
  //      }
  //    });
  //    $('.form-builder-section-prev').on('click', function () {
  //      var sectionPrev = $(this).attr('href');
  //      window.location.hash = sectionPrev;
  //    });
  //  } else {
  //    $(this).addClass('not-multi-part');
  //  }
  //});

  function formActionButtonDiv() {
    var r = $.Deferred();
    $('.form-builder-section > fieldset').append('<div class="action-buttons"/>');
    return r;
  }
  function formActionButtons() {
    var r = $.Deferred();
    $('.form-builder-section:not(:first)').each(function () {
      var sectionID = $(this).prev('.form-builder-section').attr('id');
      console.log(sectionID);
      $('> fieldset .action-buttons', this).append('<a class="btn btn-secondary float-left form-builder-section-prev" href="#' + sectionID + '">Previous</a>');
    });
    $('.form-builder-section').each(function () {
      var sectionID;
      if ($(this).next().hasClass('form-builder-submit-controls')) {
        sectionID = $(this).next('.form-builder-submit-controls').attr('id');
        // console.log(sectionID);
        $('> fieldset .action-buttons', this).append('<a class="btn btn-primary float-right form-builder-section-next" href="#' + sectionID + '">Next</a>');
      } else {
        sectionID = $(this).next('.form-builder-section').attr('id');
        // console.log(sectionID);
        $('> fieldset .action-buttons', this).append('<a class="btn btn-primary float-right form-builder-section-next" href="#' + sectionID + '">Next</a>');
      }
    });
    return r;
  }

  // tooltip
  $('[role="tooltip"]').each(function () {
    var tooltipLink = $(this);
    console.log(tooltipLink);
    var tooltip = $(this).attr('aria-describedby');
    console.log(tooltip);
    var tooltipID = '#' + tooltip;
    console.log(tooltipID);

    var tooltipLinkHeight = tooltipLink.outerHeight();
    console.log(tooltipLinkHeight);
    var tooltipLinkWidth = tooltipLink.outerWidth();
    console.log(tooltipLinkWidth);
    var tooltipLinkTop = $(this).offset().top;
    console.log(tooltipLinkTop);
    var tooltipLinkLeft = $(this).offset().left;
    console.log(tooltipLinkLeft);
    var tooltipLinkRight = ($(window).width() - ($(this).offset().left + $(this).outerWidth()));
    console.log(tooltipLinkRight);

    var tooltipTop = tooltipLinkTop + tooltipLinkHeight / 2 + 10;
    var tooltipRight = tooltipLinkRight - tooltipLinkWidth / 2;
    console.log(tooltipTop);

    $(tooltipID).css('top', tooltipTop).css('right', tooltipRight);

    $(tooltipID).remove().clone().appendTo('body');
  });
  $(document).on('click', '[role="tooltip"]', function (e) {
    e.preventDefault();
  });
  $(document).on('mouseover', '[role="tooltip"]', function (e) {
    e.preventDefault();
    var tooltip = $(this).attr('aria-describedby');
    var tooltipID = '#' + tooltip;
    $(tooltipID).addClass('moused over');
    $(tooltipID).attr('aria-hidden', 'false');
    $(tooltipID).show().addClass('visible');
  });
  $(document).on('mouseout', '[role="tooltip"]', function (e) {
    e.preventDefault();
    var tooltip = $(this).attr('aria-describedby');
    var tooltipID = '#' + tooltip;
    $(tooltipID).addClass('moused out');
    $(tooltipID).attr('aria-hidden', 'true');
    $(tooltipID).hide().removeClass('visible');
  });
  $(document).on('focus', '[role="tooltip"]', function (e) {
    e.preventDefault();
    var tooltip = $(this).attr('aria-describedby');
    var tooltipID = '#' + tooltip;
    $(tooltipID).addClass('focused');
    $(tooltipID).attr('aria-hidden', 'false');
    $(tooltipID).show().addClass('visible');
  });
  $(document).on('focusout', '[role="tooltip"]', function (e) {
    e.preventDefault();
    var tooltip = $(this).attr('aria-describedby');
    var tooltipID = '#' + tooltip;
    $(tooltipID).addClass('moused out');
    $(tooltipID).attr('aria-hidden', 'true');
    $(tooltipID).hide().removeClass('visible');
  });
  $(document).on('keyup', '[role="tooltip"]', function (e) {
    e.preventDefault();
    var tooltip = $(this).attr('aria-describedby');
    var tooltipID = '#' + tooltip;
    if (e.keyCode == 13) {
      $(tooltipID).addClass('enter pressed');
      $(tooltipID).attr('aria-hidden', 'false');
      $(tooltipID).show().addClass('visible');
    }
  });
  $(document).on('keyup', function (e) {
    e.preventDefault();
    var tooltip = $(this).attr('aria-describedby');
    var tooltipID = '#' + tooltip;
    if (e.keyCode == 27) {
      $(tooltipID).addClass('escaped');
      $(tooltipID).attr('aria-hidden', 'true');
      $(tooltipID).hide().removeClass('visible');
    }
  });
});

jQuery(document).ready(function ($) {
  speedbumpMe();
});

function speedbumpMe() {
  // find all external links
  $('a:not([href=""], [href^="http://www.troxellins.com"], [href^="https://www.troxellins.com"], [href^="http://troxellins.com"], [href^="https://troxellins.com"], [href*="troxellfinancialadvisors.com"], [href*="https://newton.newtonsoftware.com"], [href*="lrsws.co"], [href*=".local"], [href^="mailto"], [href^="tel"], [href^="/"], [href^="#"], [href^="javascript"], [class*="nospeedbump"], [id*="url-link"], [id*="close-speedbump"], [class*="close-reveal-modal"], [class*="external"])').addClass('external');
  $('a[href*="troxellfinancialadvisors.com"]').addClass('external2');

  // remove external class from links without href attribute
  $('a').each(function (e) {
    if ($(this).attr('href') === undefined) {
      $(this).removeClass('external');
    }
    if ($(this).attr('role') === 'tooltip') {
      $(this).removeClass('external');
    }
  });

  // add foundation reveal attribute to external links
  $('a.external').attr('data-reveal-id', 'speedbump');
  $('a.external2').attr('data-reveal-id', 'speedbump2');

  // add the link and title to the modal window
  $(function () {
    $('a.external').click(function () {
      var url = $(this).attr('href');
      var title = $(this).attr('title');
      $('#url_link').attr('href', url);
    });
    $('a.external2').click(function () {
      var url = $(this).attr('href');
      var title = $(this).attr('title');
      $('#url_link2').attr('href', url);
    });
  });

  // close speedbump when clicking acknowledgment button
  $('.reveal-modal .btn-cancel').click(function (e) {
    e.preventDefault();
    $('.reveal-modal').foundation('reveal', 'close');
  });

  //Team filtering
  $('.team-filter-title').click(function () {
    $('.our-team-group').addClass('active');
    $('.team-filter-list').addClass('active');
    $('.page-header').addClass('move-back');
    $('.our-team-filter .close').addClass('active');
    $('.our-team-filter .close').click(function () {
      $('.our-team-group').removeClass('active');
      $('.team-filter-list').removeClass('active');
      $('.page-header').removeClass('move-back');
      $('.our-team-filter .close').removeClass('active');
    });
    $('.team-filter-list a').click(function () {
      $('.our-team-group').removeClass('active');
      $('.team-filter-list').removeClass('active');
      $('.page-header').removeClass('move-back');
      $('.our-team-filter .close').removeClass('active');
    });
  });

  if ($('.team-filter-list a.active').length) {
    setTimeout(function () {
      $('.team-filter-list a.active').click();
    }, 1000);
  }

  $(".team-filter-list a").click(function () {
    //remove selected class from all buttons
    $("button").removeClass("selected");
    //add selected class only to clicked button
    $('.team-filter-list a.active').removeClass("active");
    $(this).addClass("active");
    //get data type filter
    var dataFilter = $(this).data('attr');
    //if data filter is all show all of them
    if (dataFilter == "all") {
      $(".team").addClass('active');
    }
    else {
      //else hide all of them and show only the one with correct data filter
      $(".team").removeClass('active');
      $("." + dataFilter).addClass('active');
    }
  });

  //Fade in on scroll
  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(window).on('resize scroll', function () {
    $('body.homepage .primary-content > .hp-box .inner').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('fadeInUp');
      }
    });
  });

  //If already in view, don't fade in
  $(window).on('load', function () {
    $('body.homepage .primary-content > .hp-box .inner').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('showing');
      }
    });
  });

  $(window).on('resize scroll', function () {
    $('body.subpage blockquote, body.subpage .highlighted-text-box, body.subpage .cta-banner').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('fadeInUp');
      }
    });
  });


  $(window).on('load', function () {
    $('body.subpage blockquote, body.subpage .highlighted-text-box, body.subpage .cta-banner').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('showing');
      }
    });
  });

  $(window).on('resize scroll', function () {
    $('body.subpage blockquote').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('fadeInLeft');
      }
    });
  });


  $(window).on('load', function () {
    $('body.subpage blockquote').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('showing');
      }
    });
  });

}
